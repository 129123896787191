import Image from '@components/image/Image'
import styles from './OutlookPluginLoading.module.css'
interface OutlookPluginLoadingProps {}

export const OutlookPluginLoading = (props: OutlookPluginLoadingProps) => {
  const {} = props
  return (
    <div className={styles['loader']}>
      <Image
        alt="NextStage Logo"
        src="/images/logo/emblem_color_sm_128x128.png"
        width={25}
        height={25}
        customStyles={{ cursor: 'pointer' }}
      />
    </div>
  )
}
