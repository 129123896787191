import { EsSourceValue } from '@nextweb/common'

function fireIfAble(msg: any, dest?: Window | null): void {
  if (typeof dest !== 'undefined' && dest !== null) {
    // To send the message to the target regardless of origin, set the target origin to "*".
    dest.postMessage(msg, '*')
  } else if (typeof window !== 'undefined') {
    window.parent?.postMessage(msg, '*')
  }
}

function createSignInFailedMessage(error: string) {
  return {
    type: 'NextStageEmbedded: SignInFailed',
    data: {
      error: error,
    },
  }
}

export function fireSignInFailedMessage(error: string, dest?: Window | null): void {
  fireIfAble(createSignInFailedMessage(error), dest)
}

function createSignInSuccessMessage() {
  return {
    type: 'NextStageEmbedded: SignInSuccess',
  }
}

export function fireSignedInSuccessMessage(dest?: Window | null): void {
  fireIfAble(createSignInSuccessMessage(), dest)
}

function createSignOutMessage() {
  return {
    type: 'NextStageEmbedded: SignedOut',
  }
}

export function fireSignedOutMessage(dest?: Window | null): void {
  fireIfAble(createSignOutMessage(), dest)
}

function createOppClickedMessage(opp: { canonId: string }) {
  const { canonId } = opp
  return {
    type: 'NextStageEmbedded: OppClicked',
    data: {
      canonId: canonId,
    },
  }
}

export function fireOppClickedMessage(opp: { canonId: string }, dest?: Window | null): void {
  fireIfAble(createOppClickedMessage(opp), dest)
}

function createTrackConversationClicked(enable: boolean) {
  return {
    type: 'NextStageEmbedded: TrackConversationClicked',
    data: {
      enable: enable,
    },
  }
}

export function fireTrackConversationClicked(enable: boolean, dest?: Window | null): void {
  fireIfAble(createTrackConversationClicked(enable), dest)
}

function createGenericBCCEmailDocument(email: string) {
  return {
    type: 'NextStageEmbedded: Doc-GenericBCCEmail',
    data: {
      email: email,
    },
  }
}

export function fireGenericBCCEmailDocument(email: string, dest?: Window | null): void {
  fireIfAble(createGenericBCCEmailDocument(email), dest)
}

function createSignInPing() {
  return {
    type: 'NextStageEmbedded: Ping-Login',
  }
}

export function fireSignInPing(dest?: Window | null): void {
  fireIfAble(createSignInPing(), dest)
}

function createWelcomeReady() {
  return {
    type: 'NextStageEmbedded: WelcomeReady',
  }
}

export function fireWelcomeReady(dest?: Window | null): void {
  fireIfAble(createWelcomeReady(), dest)
}

export const EmbeddedSearchListingSelectedEventTypeKey = 'NextStageEmbedded: SearchListingSelected'
export function createSearchListingSelected(listingEsSource: {
  // Sourcename like 'sam' or 'ciosp'
  source: string
  // Unique ID in storage (not necessarily the sourceId)
  externalId: string

  // This is the full listing object!
  listing: any
}) {
  return {
    type: EmbeddedSearchListingSelectedEventTypeKey,
    source: listingEsSource.source,
    externalId: listingEsSource.externalId,
    listing: listingEsSource.listing,
  }
}

export const EmbeddedSearchListingClickedEventTypeKey = 'NextStageEmbedded: SearchListingClicked'
export function createSearchListingClicked(listingEsSource: {
  source: string
  externalId: string

  // This is the full listing object!
  listing: any
}) {
  return {
    type: EmbeddedSearchListingClickedEventTypeKey,
    source: listingEsSource.source,
    externalId: listingEsSource.externalId,
    listing: listingEsSource.listing,
  }
}

export function fireSearchListingClicked(
  listingEsSource: {
    source: string
    externalId: string

    // This is the full listing object!
    listing: any
  },
  dest?: Window | null
): void {
  fireIfAble(createSearchListingClicked(listingEsSource), dest)
}

export function fireSearchListingSelected(
  listingEsSource: {
    // Sourcename like 'sam' or 'ciosp'
    source: string
    // Unique ID in storage (not necessarily the sourceId)
    externalId: string

    // This is the full listing object!
    listing: any
  },
  dest?: Window | null
): void {
  fireIfAble(createSearchListingSelected(listingEsSource), dest)
}

/**
 * MS Word Add-In API
 * https://learn.microsoft.com/en-us/office/dev/add-ins/reference/overview/word-add-ins-reference-overview
 */

// Receive message to read document (contains document)
// May make requests to frame to get whole document...

/**
 * Events received from Word Add-In
 */
export const Embedded_MSWord_OnDocumentContent = 'NextStageEmbedded: MSWord_OnDocumentContent'
export const Embedded_MSWord_OnDocumentSelectionChanged = 'NextStageEmbedded: MSWord_OnDocumentSelectionChanged'
export const Embedded_MSWord_OnDocumentContentRequestError = 'NextStageEmbedded: MSWord_OnDocumentContentRequestError'

/**
 * Commands sent to Word Add-In
 */
export const Embedded_MSWord_CommandRequestDocumentContent = 'NextStageEmbedded: MSWord_CommandRequestDocumentContent'
export function fireCommandRequestDocumentContent(uuid: string, dest?: Window | null): void {
  fireIfAble({ type: Embedded_MSWord_CommandRequestDocumentContent, uuid: uuid }, dest)
}

export const Embedded_MSWord_CommandInsertText = 'NextStageEmbedded: MSWord_CommandInsertText'
export function fireCommandInsertText(text: string, dest?: Window | null): void {
  fireIfAble({ type: Embedded_MSWord_CommandInsertText, text: text }, dest)
}

export const EMBEDDED_API = {
  eventSignedOut: fireSignedOutMessage,
  eventSignedIn: fireSignedInSuccessMessage,
  eventSignInFailed: fireSignInFailedMessage,
  eventOppClicked: fireOppClickedMessage,
  eventSearchOppSelected: fireSearchListingSelected,
  eventSearchOppClicked: fireSearchListingClicked,
  eventTrackConversationClicked: fireTrackConversationClicked,
  eventWelcomeReady: fireWelcomeReady,
  docGenericBCCEmail: fireGenericBCCEmailDocument,
  pingSignIn: fireSignInPing,
  mswordCommandRequestDocumentContent: fireCommandRequestDocumentContent,
  mswordCommandInsertText: fireCommandInsertText,
}
